import React from "react"

const Description = data => {
  return (
    <div className="2xl:px-[160px] sm:px-[50px] px-[10px] py-10">
      <div className="rounded-md bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] ">
        <div className="h-full w-full rounded-md items-center justify-center bg-white text-black back p-6 space-y-5">
          {data?.data?.content.map((c, idx) => (
            <p key={idx} className="text-black md:text-xl text-lg ">
              {c.description}
            </p>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Description
