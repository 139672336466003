import React from "react"

import ServiceContactForm from "./ServiceContactForm"

const Banner = props => {
  return (
    <div
      className="bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: `url(${props.bgImage})` }}
    >
      <div className="lg:flex justify-center 2xl:px-[160px] sm:px-[50px] px-[10px] gap-16 mx-auto z-10 relative">
        <div className="lg:w-1/2 grid place-content-center z-10 relative">
          <h2 className="text-center lg:text-start uppercase font-bold">
            {props.heading}
          </h2>
        </div>
        <div className="lg:w-1/2 z-10 relative">
          <ServiceContactForm />
        </div>
        <div className="absolute inset-0 bg-black/50"></div>
      </div>
    </div>
  )
}

export default Banner
