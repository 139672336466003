import React from "react"
import OurClients from "../home/OurClients"
import OurClientsData from "../../content/home/our-clients.json"

const OurTrustedPartners = () => {
  return (
    <div className="2xl:px-[160px] sm:px-[50px] px-[10px] text-center md:pb-10 pb-4 ">
      <div className="bg-white text-center text-black rounded-3xl space-y-6 p-8">
        <h2>Our trusted partners</h2>
        <OurClients data={OurClientsData} />
        <h3>
          Our mission and vision are to be a leading service provider and enable
          SMEs, MSMEs, and entrepreneurs to progress in the current position of
          the market. We know that customer growth is the company’s growth, so
          we commit our growth to helping businesses reach their goals. We
          believe in working with accuracy, transparency, reliability,
          innovation, user-friendliness, and no-compromise quality to seize
          growth and opportunities in today’s ever-evolving digital economy.
        </h3>
      </div>
    </div>
  )
}

export default OurTrustedPartners
