import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import {
  hyderabadServicesForm,
  hyderabadServicesGoogleSheet,
} from "../../services/api"
import { navigate } from "gatsby"

const ServiceContactForm = () => {
  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors, isSubmitting },
  } = useForm()

  const [fileUploaded, setFileUploaded] = useState(false)
  const [serverError, setServerError] = useState("")
  const [selectedCountryCode, setSelectedCountryCode] = useState("")

  useEffect(() => {
    if (fileUploaded) {
      const timeout = setTimeout(() => {
        setFileUploaded(false)
      }, 30000)
      return () => clearTimeout(timeout)
    }
  }, [fileUploaded])

  const onSubmit = data => {
    data.phoneNumber = selectedCountryCode + " " + data.phoneNumber

    const submissionDate = new Date()

    const formattedDate = `${submissionDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${(submissionDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${submissionDate.getFullYear()} ${submissionDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${submissionDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${submissionDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}`

    data.submissionDate = formattedDate

    hyderabadServicesGoogleSheet(data, setFileUploaded(true))
      .then(res => {
        if (res.status === 200) {
          console.log(res)
          console.log(data)
          setFileUploaded(false)
          reset()
          navigate("/thanks")
          setTimeout(() => {
            navigate("/")
          }, 10000)
          hyderabadServicesForm(data, setFileUploaded(true)).then(res => {
            console.log("contact form res ", res)
          })
        }
      })
      .catch(err => {
        alert("Failed to Submit!")
        console.log(err)
      })
  }

  return (
    <div className="md:(pt-2 pb-8) py-8">
      <div className="md:space-y-0 space-y-8">
        <div className="xl:p-10 p-4 xl:space-y-4 space-y-1 border bg-white rounded-3xl">
          <div className="text-center">
            <h2 className="text-black sm:text-2xl text-lg font-semibold">
              Contact Form
            </h2>
          </div>
          <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="fullName" className="text-black ">
                Name <span className="text-[#EB1414]">*</span>
              </label>

              <input
                id="fullName"
                name="fullName"
                type="text"
                className={`block w-full lg:p-[6px] p-[2px] border border-black/20 rounded-[4px]  bg-white focus:outline-none text-black ${
                  errors.fullName ? "border-[#EB1414]" : ""
                }`}
                {...register("fullName", {
                  required: "Full Name is required",
                  pattern: {
                    value: /^[a-zA-Z ]+$/,
                    message: "Please enter valid name",
                  },
                })}
                onKeyUp={() => {
                  trigger("fullName")
                }}
              />
              {errors.fullName && (
                <small className="text-[#EB1414]" id="Name_error">
                  {errors.fullName.message}
                </small>
              )}
            </div>

            <div>
              <label htmlFor="Email" className="text-black ">
                Email <span className="text-[#EB1414]">*</span>
              </label>
              <input
                type="text"
                aria-describedby="Email_error"
                id="Email"
                name="Email"
                className={`block w-full lg:p-[6px] p-[2px] border border-black/20 rounded-[4px]  bg-white focus:outline-none text-black ${
                  errors.email ? "border-[#EB1414]" : ""
                }`}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please enter valid email address",
                  },
                })}
                onKeyUp={() => {
                  trigger("email")
                }}
              />
              {errors.email && (
                <small className="text-[#EB1414]" id="Email_error">
                  {errors.email.message}
                </small>
              )}
            </div>

            <div>
              <label htmlFor="Phone Number" className="text-black ">
                Phone Number <span className="text-[#EB1414]">*</span>
              </label>
              <input
                type="text"
                aria-describedby="Phone_Number_error"
                id="phoneNumber"
                name="phoneNumber"
                className={`block w-full lg:p-[6px] p-[2px] border border-black/20 rounded-[4px]  bg-white focus:outline-none text-black ${
                  errors.phoneNumber ? "border-[#EB1414]" : ""
                }`}
                {...register("phoneNumber", {
                  required: "Phone Number is required",

                  pattern: {
                    value: /^\d*(?:\.\d{1,2})?$/,
                    message: "Please enter valid Phone Number",
                  },
                  maxLength: {
                    value: 10,
                    message: "Please enter 10 Digit Phone Number",
                  },
                  minLength: {
                    value: 10,

                    message: "Please enter  10 Digit Phone Number",
                  },
                })}
                onKeyUp={() => {
                  trigger("phoneNumber")
                }}
              />
              {errors.phoneNumber && (
                <small className="text-[#EB1414]" id="Phone_Number_error">
                  {errors.phoneNumber.message}
                </small>
              )}
            </div>

            <button
              type="submit"
              aria-live="polite"
              aria-atomic="true"
              className="disabled:bg-[#e55325]/40 disabled:cursor-not-allowed bg-secondary hover:duration-500 sm:col-span-3  hover:bg-primary  sm:w-44 font-medium rounded-md p-1 text-white text-[16px]"
              disabled={isSubmitting || fileUploaded}
            >
              {isSubmitting || fileUploaded ? "Please wait..." : "Submit"}
            </button>
          </form>
          {serverError && <p className="text-[#EB1414]">{serverError}</p>}
        </div>
      </div>
    </div>
  )
}

export default ServiceContactForm
