import React from "react"
import Banner from "../../components/Hyderabad/Banner"
import Layout from "../../components/layout"
import Description from "../../components/Hyderabad/Description"
import ButtonLinks from "../../components/Hyderabad/ButtonLinks"
import Faqs from "../../components/Hyderabad/Faqs"
import OurTrustedPartners from "../../components/about/OurTrustedPartners"
import bgImage from "../../../static/assets/hyderabad/web-development.svg"
import data from "../../content/hyderabad/Description/WebDevelopment.json"
import faqs from "../../content/hyderabad/Faqs/WevDevelopment.json"
import SEO from "../../components/seo"

const webDevelopment = () => {
  const keywords = [
    "Web development services in hyderabad",
    "best web development company",
    "web development services",
    "website designers in Hyderabad ",
    "web designing and web development company in Hyderabad",
  ]
  return (
    <Layout className="page">
      <SEO
        title="Best web development services in hyderabad | Cresolinfoserv"
        description="Cresolinfoserv stands as Hyderabad's foremost full-stack web development company, known for its excellence in producing beautiful and award-winning web designs"
        keywords={keywords}
      />
      <Banner
        heading="Best Web development services In Hyderabad"
        bgImage={bgImage}
      />
      <Description data={data} />
      <ButtonLinks />
      <Faqs faqs={faqs} />
      <OurTrustedPartners />
    </Layout>
  )
}

export default webDevelopment
