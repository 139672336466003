import React from "react"
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion"

const Faqs = faqs => {
  console.log(faqs)

  const AccordionItem = ({ header, ...rest }) => (
    <Item
      {...rest}
      header={({ state: { isEnter } }) => (
        <>
          <h2 className="font-medium xl:text-xl text-xl">{header}</h2>
          <img
            className={`ml-auto xl:w-[24px] w-[15px] mt-3 transition-transform duration-700 ease-in-out  ${
              isEnter && "rotate-180"
            }`}
            src="/assets/home/downArrow.svg"
            alt="downArrow"
          />
        </>
      )}
      className="border border-slate-400"
      buttonProps={{
        className: ({ isEnter }) =>
          `flex w-full xl:p-5 p-3 text-left  ${isEnter && ""}`,
      }}
      contentProps={{
        className: "transition-height duration-700  ease-in-out ",
      }}
      panelProps={{ className: "xl:px-8 p-3 w-full" }}
    />
  )

  return (
    <div className="2xl:px-[160px] sm:px-[50px] px-[10px]">
      <h2 className="lg:mt-10 text-start uppercase">{faqs?.faqs.title}</h2>

      <div className="3xl:pt-[71px] 2xl:pt-[60px] sm:pt-[35px] pt-[10px] xl:flex justify-between xl:space-x-14 space-y-10 xl:space-y-0 lg:pb-20 pb-8">
        <div className="w-full">
          <Accordion className="space-y-4" transition transitionTimeout={700}>
            {faqs?.faqs.data?.map((d, idx) => (
              <AccordionItem key={idx} header={d.label}>
                <div className="space-y-4 pb-4">
                  {d.content.map((c, idx) => (
                    <p key={idx}>{c.description}</p>
                  ))}
                </div>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default Faqs
