import { Link } from "gatsby"
import React from "react"

const ButtonLinks = () => {
  const data = [
    {
      name: "Web Development",
      link: "/Hyderabad/web-development",
    },
    {
      name: "E-commerce Development",
      link: "/Hyderabad/e-commerce-development",
    },
    {
      name: "Product Development",
      link: "/Hyderabad/product-development",
    },
    {
      name: "Mobile App Development",
      link: "/Hyderabad/mobile-app-development",
    },
    {
      name: "Testing",
      link: "/Hyderabad/testing",
    },
    {
      name: "ERP Solutions",
      link: "/Hyderabad/erp-solutions",
    },
  ]
  return (
    <div className="2xl:px-[160px] sm:px-[50px] px-[10px] py-10 grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
      {data.map((d, idx) => (
        <Link
          to={d.link}
          key={idx}
          className="rounded-3xl bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] group"
        >
          <div className="h-full w-full rounded-3xl items-center justify-center bg-white group-hover:bg-black   back p-6 space-y-5">
            <p className="text-black group-hover:text-white">{d.name}</p>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default ButtonLinks
